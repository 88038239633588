var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"flex-1 px-2 py-4 bg-gray-700 space-y-1 cohort-list"},[_vm._l((_vm.Cohorts),function(cohort){return _c('div',{key:cohort.id,staticClass:"relative"},[_c('div',{staticClass:"absolute right-2 h-full flex items-center"},[_c('input',{staticClass:"focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",attrs:{"id":"sms","name":"sms","type":"checkbox"},domProps:{"value":cohort.id},on:{"change":function($event){return _vm.$root.$emit('sms-toggle', {
						status: $event.target.checked,
						id: $event.target.value,
					})}}})]),_c('router-link',{staticClass:"bg-gray-800 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md",attrs:{"to":{
				name: 'cohort.show',
				params: { id: cohort.id, current: cohort.id },
			}}},[_c('CohortError',{attrs:{"error":cohort.error}}),_vm._v(" "+_vm._s(cohort.name)+" ")],1)],1)}),_c('button',{staticClass:"w-full text-left text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-normal rounded-md",on:{"click":function($event){return _vm.setSlideoverWrapper({
				current: true,
				related_id: null,
			})}}},[_c('svg',{staticClass:"text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"currentColor","viewBox":"0 0 20 20","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M6 2c-1.10457 0-2 .89543-2 2v12c0 1.1046.89543 2 2 2h8c1.1046 0 2-.8954 2-2V7.41421c0-.53043-.2107-1.03914-.5858-1.41421L12 2.58579C11.6249 2.21071 11.1162 2 10.5858 2H6zm5 6c0-.55228-.4477-1-1-1-.55228 0-1 .44772-1 1v2H7c-.55228 0-1 .4477-1 1s.44772 1 1 1h2v2c0 .5523.44771 1 1 1 .5523 0 1-.4477 1-1v-2h2c.5523 0 1-.4477 1-1s-.4477-1-1-1h-2V8z"}})]),_vm._v(" "+_vm._s(_vm.$t('Add cohort'))+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }