<template>
	<div class="flex h-full">
		<div
			class="bg-gray-200 min-w-64 lg:min-w-72 xl:min-w-92 border-r border-gray-300 overflow-y-auto"
			v-if="Root"
		>
			<div class="px-4 sm:px-4 md:px-6 py-6">
				<div class="text-sm font-bold text-gray-500">
					{{ Root.name ? Root.name : '' }}
				</div>
				<button
					class="w-full text-left text-blue-500 hover:text-blue-700 flex items-center text-xs font-normal"
					@click="
						setSlideoverWrapper({
							current: true,
							related_id: Cohort.id,
						})
					"
				>
					{{ $t('Add cohort') }}
				</button>
			</div>
			<div class="">
				<child
					v-for="child in sorted"
					:key="child.id"
					:cohort="child"
				></child>
				<button
					class="m-6 px-4 py-1 bg-blue-300 text-blue-700 rounded"
					@click="setSmsWrapper"
				>
					{{ $t('Send sms') }}
				</button>
			</div>
		</div>
		<!--<div class="px-4 sm:px-6 md:px-8 py-6 flex-1" v-if="Cohort">-->
		<div class="px-4 sm:px-6 md:px-8 py-6 flex-1">
			<!-- This example requires Tailwind CSS v2.0+ -->
			<div class="flex items-center justify-between">
				<div class="flex-1 min-w-0">
					<router-link
						v-if="
							Cohort &&
							$store.state.cohorts.root !== Cohort.cohort_id &&
							Parent
						"
						:to="{
							name: 'cohort.show',
							params: {
								id: $store.state.cohorts.root,
								current: Cohort.cohort_id,
							},
						}"
						class="mt-2 flex items-center text-xs text-gray-500"
					>
						<!-- Heroicon name: calendar -->
						<chevronUp class="w-6 text-gray-500" />
						{{ Parent.name ? Parent.name : '' }}
					</router-link>
					<h2
						class="text-xl font-bold leading-7 text-gray-700 sm:text-3xl truncate"
						style="max-width:300px;"
					>
						{{ Cohort.name ? Cohort.name : '' }}
					</h2>
					<div
						class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
					>
						<!--<div class="mt-2 flex items-center text-sm text-gray-500">

						<svg
							class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fill-rule="evenodd"
								d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
								clip-rule="evenodd"
							/>
						</svg>
						Status is ok
					</div>-->
					</div>
					<div class="text-xs text-gray-500" v-if="Cohort.reference_1">
						Reference: <strong>{{ Cohort.reference_1 }}</strong>
					</div>
					<div v-if="Cohort.description">
						{{ Cohort.description }}
					</div>
				</div>
				<div class="mt-5 flex mt-0 ml-4">
					<span class="hidden lg:block" v-if="Cohort.cohorts.length == 0">
						<button
							@click="$root.$emit('generate-report')"
							type="button"
							class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						>
							<!-- Heroicon name: pencil -->
							<qr class="w-5 mr-2 -ml-1" />
							{{ $t('Download') }}
						</button>
					</span>

					<!-- Dropdown -->
					<span class="ml-3 relative" v-click-outside="hideDropdown">
						<button
							@click="show_dropdown = !show_dropdown"
							type="button"
							class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							id="mobile-menu"
							aria-haspopup="true" 
						>
							<!-- Heroicon name: chevron-down -->
							<svg
								class="-mr-1 -ml-1 h-5 w-5 text-gray-500"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
							>
								<path
									fill-rule="evenodd"
									d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
									clip-rule="evenodd"
								/>
							</svg>
						</button>
						<transition
							enter-active-class="transition ease-out duration-200"
							enter-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95"
						>
							<div
								v-if="show_dropdown"
								class="origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
								aria-labelledby="mobile-menu"
								role="menu"
							>
								<!--<a
									href="#"
									class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
									role="menuitem"
									>{{ $t('Reset qr') }}</a
								>-->
								<button
									v-if="Cohort.cohorts.length == 0"
									@click="$root.$emit('generate-report')"
									role="menuitem"
									class="block lg:hidden w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
								>
									{{ $t('Download') }} QR
								</button>
								<button
									@click="
										editCohort({
											current: true,
											related_id: Cohort.id,
											edit:Cohort
										})
									"
									class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
									role="menuitem"
								>
									{{ $t('Edit cohort') }}
								</button>
								<button
									@click="deleteCohort(Cohort)"
									class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
									role="menuitem"
								>
									{{ $t('Delete cohort') }}
								</button>
							</div>
						</transition>
					</span>
				</div>
			</div>

			<router-view :key="Cohort !== undefined ? Cohort.id : 1" />

			<!--<singleCohort />-->
		</div>
	</div>
</template>
<script>
	import child from '../../components/cohort/child';
	import ClickOutside from 'vue-click-outside';
	// import singleCohort from './Single';
	import chevronUp from '../../assets/svg/chevron_up.svg';
	import qr from '../../assets/svg/qr.svg';
	import { mapMutations, mapGetters, mapActions } from 'vuex';
	export default {
		directives: {
			ClickOutside,
		},
		components: {
			child,
			// singleCohort,
			chevronUp,
			qr,
		},
		watch: {
			'$route.params.id': {
				immediate: true,
				handler(cohort) {
					if (cohort) {
						this.setParent(this.$router.currentRoute.params.id);
						// this.Cohort = this.$store.state.user.business.cohorts.find(
						// 	(cohort) => {
						// 		return (
						// 			cohort.id ==
						// 			this.$router.currentRoute.params.id
						// 		);
						// 	}
						// );
					}
				},
			},
			'$route.params.current': {
				immediate: true,
				handler(cohort) {
					if (cohort) {
						this.setCurrent(
							this.$router.currentRoute.params.current
						);
					}
				},
			},
		},
		methods: {
			hideDropdown() {
				this.show_dropdown = false;
			},
			editCohort({current,related_id,edit}) {
				this.setSlideoverWrapper({current,related_id});
				this.$root.$emit('edit-cohort',edit);
			},
			...mapMutations([
				'setCurrent',
				'setSlideoverWrapper',
				'setParent',
				'setSmsWrapper',
			]),
			...mapActions(['deleteCohort']),
		},
		computed: {
			sorted() {
				const ch = this.Root.cohorts;
				return ch.sort((a, b) => {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				});
			},
			...mapGetters(['Cohort', 'Root', 'Parent']),
			// Cohort() {
			// 	console.log('here');
			// 	return this.$store.state.user.business.cohorts.find(
			// 		(cohort) => {
			// 			return cohort.id == this.$router.currentRoute.params.id;
			// 		}
			// 	);
			// },
		},
		data() {
			return {
				show_dropdown: false,
			};
		},
	};
</script>
