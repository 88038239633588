<template>
	<slideover>
		<form
			@submit.prevent="submitForm"
			class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
		>
			<div class="flex-1 h-0 overflow-y-auto">
				<div class="py-6 px-4 bg-indigo-700 sm:px-6">
					<div class="flex items-center justify-between">
						<h2
							id="slide-over-heading"
							class="text-lg font-medium text-white"
						>
							<span v-if="!editing">{{ $t('Create cohort') }}</span>
							<span v-if="editing">{{ $t('Edit cohort') }}</span>
						</h2>
						<div class="ml-3 h-7 flex items-center">
							<button
								@click="close"
								type="button"
								class="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
							>
								<span class="sr-only">{{
									$t('Close panel')
								}}</span>
								<!-- Heroicon name: x -->
								<svg
									class="h-6 w-6"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
						</div>
					</div>
					<div class="mt-1">
						<p v-if="!editing" class="text-sm text-indigo-300">
							{{
								$t(
									'Create new cohort or group by filling out the information in the form below'
								)
							}}
						</p>
						<p v-if="editing" class="text-sm text-indigo-300">
							{{
								$t(
									'Update this cohort. The cohort QR code is not changed, only the related information'
								)
							}}
						</p>
					</div>
				</div>
				<div class="flex-1 flex flex-col justify-between">
					<div class="px-4 divide-y divide-gray-200 sm:px-6">
						<div class="space-y-6 pt-6 pb-5">
							<div>
								<label
									for="project_name"
									class="block text-sm font-medium text-gray-900"
								>
									{{ $t('Cohort name') }}
								</label>
								<div class="mt-1">
									<input
										v-model="cohort.name"
										type="text"
										name="project_name"
										id="project_name"
										:disabled="editing"
										class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
									/>
								</div>
							</div>
							<div>
								<label
									for="reference_1"
									class="block text-sm font-medium text-gray-900"
								>
									{{ $t('External reference') }}
								</label>
								<div class="mt-1">
									<input
										v-model="cohort.reference_1"
										type="text"
										name="reference_1"
										id="reference_1"
										class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
									/>
								</div>
							</div>
							<div>
								<label
									for="description"
									class="block text-sm font-medium text-gray-900"
								>
									{{ $t('Description / notes') }}
								</label>
								<div class="mt-1">
									<textarea
										v-model="cohort.description"
										id="description"
										name="description"
										rows="4"
										class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
									></textarea>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="flex-shrink-0 px-4 py-4 flex justify-end">
				<button
					@click="close"
					type="button"
					class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				>
					{{ $t('Cancel') }}
				</button>
				<button
					type="submit"
					class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				>
					{{ $t('Save') }}
				</button>
			</div>
		</form>
	</slideover>
</template>
<script>
	import slideover from '../modal/slideover.vue';
	import { mapMutations, mapActions, mapGetters } from 'vuex';
	export default {
		components: { slideover },
		created(){
			this.$root.$on('edit-cohort',this.edit)
		},
		methods: {
			clear(){
				this.cohort = {
					id: null,
					name: '',
					description: '',
					reference_1: '',
					cohort_id: null,
				};
			},
			close(){
				this.setSlideoverInner(false)
				this.clear();
				this.editing = false;
			},
			edit(cohort) {
				this.cohort = cohort
				this.editing = true;
			},
			...mapActions(['createCohort','updateCohort']),
			...mapMutations(['setSlideoverInner']),
			async submitForm() {
				if(!this.editing) {
					this.cohort.cohort_id = this.$store.state.cohorts.related;
					await this.createCohort(this.cohort);
					this.clear()
				} else {
					if(confirm('This will update the Cohort information. Are you sure?')) {
						await this.updateCohort(this.cohort);
						this.clear()
					}
				}
				
				
			},
		},
		computed: {
			...mapGetters(['slideover', 'Cohorts']),
		},
		data() {
			return {
				editing:false,
				visible: false,
				cohort: {
					id: null,
					name: '',
					description: '',
					reference_1: '',
					cohort_id: null,
				},
			};
		},
	};
</script>
