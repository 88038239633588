<template>
	<div class="w-full">
		<registeredPersons />
		<pdfGenerator />
	</div>
</template>
<script>
	import { mapGetters, mapActions } from 'vuex';

	import registeredPersons from '../../components/cohort/registeredPersons';
	import pdfGenerator from '../pdf/generator';
	export default {
		components: {
			registeredPersons,
			pdfGenerator,
		},
		methods: {
			...mapActions(['deleteCohort']),
		},
		computed: {
			...mapGetters(['Cohort', 'Parent']),
		},
		data() {
			return {
				show_dropdown: false,
			};
		},
	};
</script>
