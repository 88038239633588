<template>
	<transition v-on:after-enter="afterEnter">
		<div class="md:hidden" v-if="mobile_nav.wrapper">
			<div class="fixed inset-0 flex z-40">
				<transition
					enter-active-class="transition-opacity ease-linear duration-300"
					enter-class="opacity-0"
					enter-to-class="opacity-100"
					leave-active-class="transition-opacity ease-linear duration-300"
					leave-class="opacity-100"
					leave-to-class="opacity-0"
					v-on:after-leave="afterLeave"
				>
					<div
						v-if="mobile_nav.inner"
						class="fixed inset-0"
						aria-hidden="true"
					>
						<div
							class="absolute inset-0 bg-gray-600 opacity-75"
						></div>
					</div>
				</transition>
				<transition
					enter-active-class="transition ease-in-out duration-300 transform"
					enter-class="-translate-x-full"
					enter-to-class="translate-x-0"
					leave-active-class="transition ease-in-out duration-300 transform"
					leave-class="translate-x-0"
					leave-to-class="-translate-x-full"
				>
					<div
						v-if="mobile_nav.inner"
						class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-700"
					>
						<div class="absolute top-0 right-0 -mr-12 pt-2">
							<button
								@click="setMobileNavInner(false)"
								class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
							>
								<span class="sr-only">Close sidebar</span>
								<!-- Heroicon name: x -->
								<svg
									class="h-6 w-6 text-white"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
						</div>
						<div class="flex-shrink-0 flex items-center px-4">
							<Logo class="h-10"></Logo>
						</div>
						<div class="mt-5 flex-1 h-0 overflow-y-auto">
							<nav-list></nav-list>
						</div>
					</div>
				</transition>
				<div class="flex-shrink-0 w-14" aria-hidden="true">
					<!-- Dummy element to force sidebar to shrink to fit close icon -->
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	import { mapGetters, mapMutations } from 'vuex';
	import navList from './cohort/navList.vue';
	import Logo from '../assets/svg/logo.svg';
	export default {
		components: { navList, Logo },
		name: 'OffCanvas',
		methods: {
			...mapMutations(['setMobileNavWrapper', 'setMobileNavInner']),
			afterEnter() {
				this.setMobileNavInner(true);
			},
			afterLeave() {
				this.setMobileNavWrapper(false);
			},
		},
		computed: {
			...mapGetters(['mobile_nav']),
		},
		data() {
			return {
				mobileNavVisible: false,
				mobileNavVisibleAfter: false,
			};
		},
	};
</script>
>
