<template>
	<div class="mt-10">
		<h2 class="uppercase text-gray-400 text-xs font-bold">
			{{ $t('Registered persons') }}
		</h2>
		<div class="flex flex-col mt-3">
			<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div
					class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
				>
					<div
						class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
					>
						<table class="min-w-full divide-y divide-gray-200">
							<thead class="bg-gray-50">
								<tr>
									<th
										scope="col"
										class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
									>
										{{ $t('Name') }}
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
									>
										{{ $t('Registered') }}
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
									>
										{{ $t('Last test') }}
									</th>
									<!--<th
										scope="col"
										class="relative px-6 py-3 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
									>
										<span class="sr-only"></span>
										...
									</th>-->
								</tr>
							</thead>
							<tbody>
								<!-- Odd row -->
								<tr
									class="bg-white"
									:class="{
										'bg-gray-50': 0 == key % 2,
									}"
									:key="person.id"
									v-for="(person, key) in people"
								>
									<td
										class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
									>
										{{ person.name }}
									</td>
									<td
										class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
									>
										{{ day(person.pivot.created_at) }}
									</td>
									<td
										class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
									>
										{{ lastTest(person.id) }}
									</td>
									<!--<td
										class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
									>
										<input
											id="candidates"
											name="candidates"
											type="checkbox"
											class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
										/>
									</td>-->
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex';
	import dayjs from 'dayjs';
	import axios from 'axios';
	export default {
		name: 'RegisteredPersons',
		mounted() {
			this.getPersons();
		},
		methods: {
			lastTest(id) {
				return this.day(this.samples[id]?.created_at);
			},
			getPersons() {
				axios
					.get('/api/v1/cohort/' + this.Cohort.id)
					.then((response) => {
						this.people = response.data.data.people;
						this.samples = response.data.samples;
					});
			},
			day(time) {
				return time ? dayjs(time).format('D.M.YYYY') : '';
			},
		},
		computed: {
			...mapGetters(['Cohort', 'Parent']),
		},
		data() {
			return {
				people: [],
				samples: [],
			};
		},
	};
</script>
