<template>
	<div id="app">
		<router-view />

		<Toast />
	</div>
</template>

<script>
	import Toast from '../components/modal/toast';
	export default {
		components: {
			Toast,
		},
		name: 'NotAuthenticatedLayout',
	};
</script>
