<template>
	<div class="h-screen flex overflow-hidden bg-gray-100">
		<!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
		<OffCanvas></OffCanvas>
		<Sidebar></Sidebar>
		<div class="flex flex-col w-0 flex-1 overflow-hidden">
			<div class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
				<button
					@click="setMobileNavWrapper(true)"
					class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
				>
					<span class="sr-only">Open sidebar</span>
					<!-- Heroicon name: menu-alt-2 -->
					<svg
						class="h-6 w-6"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						aria-hidden="true"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M4 6h16M4 12h16M4 18h7"
						/>
					</svg>
				</button>
				<div class="flex-1 px-4 flex justify-between">
					<div class="flex-1 flex">
						<!--<form
							class="w-full flex md:ml-0"
							action="#"
							method="GET"
						>
							<label for="search_field" class="sr-only"
								>Search</label
							>
							<div
								class="relative w-full text-gray-400 focus-within:text-gray-600"
							>
								<div
									class="absolute inset-y-0 left-0 flex items-center pointer-events-none"
								>
									
									<svg
										class="h-5 w-5"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true"
									>
										<path
											fill-rule="evenodd"
											d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
											clip-rule="evenodd"
										/>
									</svg>
								</div>
								<input
									id="search_field"
									class="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
									placeholder="Search"
									type="search"
									name="search"
								/>
							</div>
						</form>-->
					</div>
					<div class="ml-4 flex items-center md:ml-6">
						<!--<button
							class="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						>
							<span class="sr-only">View notifications</span>
							
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
								/>
							</svg>
						</button>-->

						<ProfileDropdown>
							<!--<a
								href="#"
								class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
								role="menuitem"
								>Your Profile</a
							>
							<a
								href="#"
								class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
								role="menuitem"
								>Settings</a
							>-->
							<LogoutButton
								class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
								role="menuitem"
								>Sign out</LogoutButton
							>
						</ProfileDropdown>
					</div>
				</div>
			</div>

			<main
				class="flex-1 relative overflow-y-auto focus:outline-none"
				tabindex="0"
			>
				<div class="h-full bg-gray-50">
					<div class="flex-1 mx-auto h-full">
						<show></show>
						<!--<router-view
							:key="Cohort !== undefined ? Cohort.id : 1"
						/>-->
					</div>
				</div>
			</main>
		</div>
		<smsModal />
		<createCohort></createCohort>
		<Toast />
	</div>
</template>
<script>
	import ProfileDropdown from '../components/ProfileDropdown';
	import LogoutButton from '../components/LogoutButton';
	import OffCanvas from '../components/OffCanvas';
	import Sidebar from '../components/Sidebar';
	import smsModal from '../components/SmsModal';
	import show from '../pages/cohort/show';
	import Toast from '../components/modal/toast';
	import { mapMutations } from 'vuex';
	import createCohort from '../components/cohort/createCohort.vue';
	export default {
		name: 'LayoutIsAuth',
		methods: {
			...mapMutations(['setMobileNavWrapper']),
		},
		components: {
			ProfileDropdown,
			LogoutButton,
			OffCanvas,
			Sidebar,
			smsModal,
			createCohort,
			show,
			Toast,
		},
	};
</script>
