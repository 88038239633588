<template>
	<div class="hello">
		<div class="py-10 flex items-center justify-center">
			<button
				class="px-10 py-2 rounded bg-blue-500 text-white"
				@click="
					setSlideoverWrapper({
						current: true,
						related_id: null,
					})
				"
			>
				Create
			</button>
		</div>
		<intro class="w-full" />
	</div>
</template>

<script>
	import intro from '../assets/svg/Intro.svg';
	import { mapMutations } from 'vuex';
	export default {
		name: 'HelloWorld',
		components: {
			intro,
		},
		methods: {
			...mapMutations(['setSlideoverWrapper']),
		},
		props: {
			msg: String,
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	h3 {
		margin: 40px 0 0;
	}
	ul {
		list-style-type: none;
		padding: 0;
	}
	li {
		display: inline-block;
		margin: 0 10px;
	}
	a {
		color: #42b983;
	}
</style>
