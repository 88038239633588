<template>
	<transition v-on:after-enter="afterEnter">
		<div
			class="fixed inset-0 overflow-hidden z-50"
			v-if="slideover.wrapper"
		>
			<div class="absolute inset-0 overflow-hidden">
				<section
					class="absolute inset-y-0 pl-16 max-w-full right-0 flex"
					aria-labelledby="slide-over-heading"
				>
					<transition
						enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
						enter-class="translate-x-full"
						enter-to-class="translate-x-0"
						leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
						leave-class="optranslate-x-0"
						leave-to-class="translate-x-full"
						v-on:after-leave="afterLeave"
					>
						<div class="w-screen max-w-md" v-if="slideover.inner">
							<slot></slot>
						</div>
					</transition>
				</section>
			</div>
		</div>
	</transition>
</template>
<script>
	import { mapMutations, mapGetters } from 'vuex';
	export default {
		methods: {
			...mapMutations(['setSlideoverWrapper', 'setSlideoverInner']),
			afterEnter() {
				this.setSlideoverInner(true);
			},
			afterLeave() {
				this.setSlideoverWrapper(false);
			},
		},
		computed: {
			...mapGetters(['slideover']),
		},
	};
</script>
