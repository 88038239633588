<template>
	<nav class="flex-1 px-2 py-4 bg-gray-700 space-y-1 cohort-list">
		<!-- Current: "bg-gray-200 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
		<div class="relative" 
			v-for="cohort in Cohorts"
			:key="cohort.id"
		>
			<div class="absolute right-2 h-full flex items-center">
				<input
					id="sms"
					name="sms"
					type="checkbox"
					class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
					:value="cohort.id"
					@change="
						$root.$emit('sms-toggle', {
							status: $event.target.checked,
							id: $event.target.value,
						})
					"
				/>
			</div>
			<router-link
				:to="{
					name: 'cohort.show',
					params: { id: cohort.id, current: cohort.id },
				}"
				class="bg-gray-800 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
			>
				<CohortError :error="cohort.error" />
				{{ cohort.name }}
			</router-link>
		</div>
		<button
			class="w-full text-left text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-normal rounded-md"
			@click="
				setSlideoverWrapper({
					current: true,
					related_id: null,
				})
			"
		>
			<svg
				class="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6"
				xmlns="http://www.w3.org/2000/svg"
				fill="currentColor"
				viewBox="0 0 20 20"
				aria-hidden="true"
			>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M6 2c-1.10457 0-2 .89543-2 2v12c0 1.1046.89543 2 2 2h8c1.1046 0 2-.8954 2-2V7.41421c0-.53043-.2107-1.03914-.5858-1.41421L12 2.58579C11.6249 2.21071 11.1162 2 10.5858 2H6zm5 6c0-.55228-.4477-1-1-1-.55228 0-1 .44772-1 1v2H7c-.55228 0-1 .4477-1 1s.44772 1 1 1h2v2c0 .5523.44771 1 1 1 .5523 0 1-.4477 1-1v-2h2c.5523 0 1-.4477 1-1s-.4477-1-1-1h-2V8z"
				/>
			</svg>
			{{ $t('Add cohort') }}
		</button>
	</nav>
</template>
<script>
	import CohortError from './cohortError';
	import { mapGetters, mapMutations } from 'vuex';
	export default {
		components: {
			CohortError,
		},
		computed: {
			...mapGetters(['Cohorts']),
		},
		methods: {
			...mapMutations(['setSlideoverWrapper']),
		},
	};
</script>
