<template>
	<slideover>
		<form
			@submit.prevent="submitForm"
			class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
		>
			<div class="flex-1 h-0 overflow-y-auto">
				<div class="py-6 px-4 bg-indigo-700 sm:px-6">
					<div class="flex items-center justify-between">
						<h2
							id="slide-over-heading"
							class="text-lg font-medium text-white"
						>
							{{ $t('Send message') }}
						</h2>
						<div class="ml-3 h-7 flex items-center">
							<button
								@click="setSmsInner(false)"
								type="button"
								class="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
							>
								<span class="sr-only">{{
									$t('Close panel')
								}}</span>
								<!-- Heroicon name: x -->
								<svg
									class="h-6 w-6"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
						</div>
					</div>
					<div class="mt-1">
						<p class="text-sm text-indigo-300">
							{{ $t('Send sms to all people in the cohort') }}
						</p>
					</div>
				</div>
				<div class="flex-1 flex flex-col justify-between">
					<div class="px-4 divide-y divide-gray-200 sm:px-6">
						<div class="space-y-6 pt-6 pb-5">
							<div>
								<label
									for="description"
									class="block text-sm font-medium text-gray-900"
								>
									{{ $t('Message') }}
								</label>
								<div class="mt-1">
									<textarea
										id="description"
										name="description"
										v-model="message"
										rows="4"
										maxlength="160"
										class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
									></textarea>
								</div>
								<div
									class="mt-1 text-xs text-gray-400"
									:class="{
										'text-red-600':
											160 - message.length < 20,
									}"
								>
									{{ 160 - message.length }}
									{{ $t('characters left') }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="flex-shrink-0 px-4 py-4 flex justify-end">
				<button
					@click="setSmsInner(false)"
					type="button"
					class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				>
					{{ $t('Cancel') }}
				</button>
				<button
					:disabled="!readyToSend"
					type="submit"
					class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-300"
				>
					{{ $t(sendLabel) }}
				</button>
			</div>
		</form>
	</slideover>
</template>
<script>
	import slideover from './modal/smsSlideover.vue';
	import axios from 'axios';
	import { mapMutations, mapGetters } from 'vuex';
	export default {
		/* eslint-disable-next-line */
		beforeRouteLeave(from, to, next) {
			console.log('to');
		},
		mounted() {
			this.$root.$on('sms-toggle', this.addSmsCohort);
		},
		created() {
			
		},
		components: {
			slideover,
		},
		methods: {
			...mapMutations(['setSmsInner']),
			addSmsCohort(e) {
				this.cohorts[e.id] = e.status;
			},
			submitForm() {
				this.sendLabel = '...';
				let result = [],
					key;
				for (key in this.cohorts) {
					if (this.cohorts[key] !== false) {
						result.push(key);
					}
				}
				axios
					.post('api/v1/sms/send', {
						message: this.message,
						cohorts: result,
						users: [],
					})
					.then(() => {
						this.setSmsInner(false);
						this.sendLabel = 'Send';
						alert(this.$t('Message was sent'));
					})
					.catch(() => {
						this.sendLabel = 'Send';
					});
			},
		},
		computed: {
			...mapGetters(['sms']),
			readyToSend() {
				return this.message.length > 10;
			},
		},
		data() {
			return {
				sendLabel: 'Send',
				visible: false,
				message: '',
				cohorts: {},
			};
		},
	};
</script>
