<template>
	<component :is="component"></component>
</template>

<script>
	// eslint-ignore

	import { mapGetters } from 'vuex';
	export default {
		name: 'App',

		computed: {
			...mapGetters(['isAuthenticated', 'Cohort']),
			component() {
				return this.isAuthenticated ? 'layoutIsAuth' : 'layoutNotAuth';
			},
		},
	};
</script>
