<template>
	<div
		class="rounded-full w-2 h-2 mr-3 ml-1"
		:class="{
			'bg-red-400': error,
			'bg-green-400': !error,
		}"
	></div>
</template>

<script>
	export default {
		props: ['error'],
	};
</script>
