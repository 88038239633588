var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-l border-gray-400 border-dashed ml-5"},[_c('div',{staticClass:"child-link relative flex text-sm justify-between items-center w-full group"},[_c('div',{staticClass:"absolute h-px w-2 bg-gray-400"}),_c('div',{staticClass:"py-2 flex w-full ml-3"},[(_vm.cohort.cohorts.length)?_c('button',{staticClass:"text-xs uppercase",on:{"click":function($event){_vm.show = !_vm.show}}},[(_vm.show)?_c('ChevronDown',{staticClass:"w-4"}):_vm._e(),(!_vm.show)?_c('ChevronRight',{staticClass:"w-4"}):_vm._e()],1):_vm._e(),(!_vm.cohort.cohorts.length)?_c('div',{staticClass:"text-xs uppercase flex items-center"},[_c('CohortIcon',{staticClass:"w-4"})],1):_vm._e(),_c('router-link',{staticClass:"flex-1 ml-3",attrs:{"to":{
					name: 'cohort.show',
					params: {
						id: _vm.$store.state.cohorts.root,
						current: _vm.cohort.id,
					},
				}},nativeOn:{"click":function($event){_vm.show = true}}},[_c('div',{class:{
						' text-indigo-600 underline': _vm.selected,
					}},[_vm._v(" "+_vm._s(_vm.cohort.name)+" ")]),_c('div',{staticClass:"text-xs text-gray-500"},[_c('span',{staticClass:"mr-3"},[_vm._v("("+_vm._s(_vm.cohort.people_count)+")")]),(_vm.cohort.cohorts.length)?_c('span',[_vm._v(_vm._s(_vm.cohort.cohorts.length)+" "+_vm._s(_vm.$t( _vm.cohort.cohorts.length > 1 ? 'cohort.cohorts' : 'cohort.cohort' )))]):_vm._e()])])],1),_c('div',{staticClass:"space-x-3 flex items-center"},[(_vm.cohort.people_count == 0)?_c('button',{staticClass:"group-hover:opacity-100 opacity-0 transition-opacity ease-linear delay-150 duration-50 text-xs text-blue-100 bg-blue-600 p-1 mr-2 leading-none rounded",on:{"click":function($event){return _vm.setSlideoverWrapper({
						current: true,
						related_id: _vm.cohort.id,
					})}}},[_vm._v(" Add sub cohort ")]):_vm._e()]),_c('div',{staticClass:"py-2"},[_c('input',{staticClass:"focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",attrs:{"id":"sms","name":"sms","type":"checkbox"},domProps:{"checked":_vm.isChecked,"value":_vm.cohort.id},on:{"change":_vm.toggleSms}}),_c('CohortError',{staticClass:"mt-1",attrs:{"error":_vm.cohort.error}})],1)]),_c('div',{staticClass:"overflow-hidden"},[_c('transition',{attrs:{"enter-active-class":"transition ease-in-out duration-300 transform","enter-class":"-translate-y-full","enter-to-class":"translate-y-0","leave-active-class":"transition ease-in-out duration-300 transform","leave-class":"translate-y-0","leave-to-class":"-translate-y-full"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"relative"},_vm._l((_vm.sorted),function(child){return _c('child',{key:child.id,attrs:{"number":_vm.indentation,"cohort":child,"is-parent-checked":_vm.isChecked}})}),1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }