<template>
	<div
		class="min-h-screen bg-gray-800 flex flex-col justify-start py-12 sm:px-6 lg:px-8"
	>
		<div class="sm:mx-auto sm:w-full sm:max-w-md">
			<SalivaIcon class="h-16 mx-auto" />
			<h2 class="mt-6 text-center text-3xl font-extrabold text-gray-400">
				Create a new account
			</h2>
			<p class="mt-2 text-center text-sm text-gray-400 max-w">
				Or
				<router-link
					:to="{ name: 'auth.login' }"
					class="font-medium text-indigo-200 hover:text-indigo-500"
				>
					login
				</router-link>
			</p>
		</div>

		<div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
			<div
				class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 relative"
			>
				<div
					v-if="loading"
					class="absolute inset-0 bg-gray-200 bg-opacity-75 z-50 sm:rounded-lg flex items-center justify-center"
				>
					<Loader class="w-16 mx-auto animate-spin text-gray-500" />
				</div>
				<form @submit.prevent="submit" class="space-y-6">
				<div class="space-y-6">
					<h3 class="text-xs text-gray-500 uppercase font-bold mb-2 text-center">Company information</h3>
					<InputWithError
						:error="getError('name')"
						:type="'text'"
						:name="'business_name'"
						:label="'Company name'"
						:placeholder="''"
						:value.sync="business.name"
					/>
					<InputWithError
						:error="getError('name')"
						:type="'text'"
						:name="'address'"
						:label="'Address'"
						:placeholder="''"
						:value.sync="business.address"
					/>
					<div class="flex space-x-2">
					<InputWithError
						:error="getError('name')"
						:type="'text'"
						:name="'zipcode'"
						:label="'Zipcode'"
						:placeholder="''"
						:value.sync="business.zipcode"
					/>
					<InputWithError
						:error="getError('name')"
						:type="'text'"
						:name="'city'"
						:label="'City'"
						:placeholder="''"
						:value.sync="business.city"
					/>
					</div>
					<InputWithError
						:error="getError('name')"
						:type="'email'"
						:name="'email'"
						:label="'Company email'"
						:placeholder="''"
						:value.sync="business.email"
					/>
					<InputWithError
						:error="getError('name')"
						:type="'text'"
						:name="'org'"
						:label="'Org nr'"
						:placeholder="''"
						:value.sync="business.org"
					/>
				</div>
				<div class="space-y-6">
					<h3 class="text-xs text-gray-500 uppercase font-bold mb-2 text-center">User details</h3>
					<InputWithError
						:error="getError('user.name')"
						:type="'text'"
						:name="'name'"
						:label="'Name'"
						:placeholder="'Firstname Lastname'"
						:value.sync="business.user.name"
					/>
					<InputWithError
						:error="getError('user.email')"
						:type="'email'"
						:name="'email'"
						:label="'Email address'"
						:placeholder="'name@example.com'"
						:value.sync="business.user.email"
					/>
					<InputWithError
						:error="getError('user.password')"
						:type="'password'"
						:name="'password'"
						:label="'Password'"
						:placeholder="''"
						:value.sync="business.user.password"
					/>
				</div>
					<div>
						<button
							:disabled="!readyToSubmit"
							type="submit"
							class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 disabled:bg-gray-300 disabled:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						>
							Register
						</button>
					</div>
				</form>

				<MoreInformation />
			</div>
		</div>
	</div>
</template>

<script>
	import SalivaIcon from '../../assets/svg/logo.svg';
	import Loader from '../../assets/svg/loader.svg';
	import InputWithError from '../../components/form/InputWithError';
	import MoreInformation from '../../components/auth/MoreInformation';
	import { mapActions } from 'vuex';
	export default {
		components: {
			SalivaIcon,
			Loader,
			InputWithError,
			MoreInformation,
		},
		name: 'Register',
		methods: {
			isError(prop) {
				return this.errors[prop] !== undefined;
			},
			getError(prop) {
				return this.isError(prop) ? this.errors[prop][0] : '';
			},
			...mapActions(['register']),
			async submit() {
				this.loading = true;
				try {
					await this.register(this.business).then((response) => {
						console.log(response)
						if(response.data.approved == 0) {
							this.loading = false;
							this.$store.commit('setErrors', {
								0: 'Your account is beeing reviewed. Please wait.',
							});
							this.business = {
								name: '',
								user: {
									name: '',
									email: '',
									password: '',
								},
							};
							this.$router.push('/login');
						}
						this.loading = false
						this.$router.push('/');
						this.showError = false;
					});
				} catch (e) {
					this.showError = true;
					this.errors = e.response.data.errors;
					console.log(e)
					this.loading = false;
				}
			},
		},
		computed: {
			readyToSubmit() {
				return (
					(this.business.name &&
						this.business.user.name &&
						this.business.user.email &&
						this.business.user.password) !== ''
				);
			},
		},
		data() {
			return {
				business: {
					name: '',
					user: {
						name: '',
						email: '',
						password: '',
					},
				},
				loading: false,
				errors: {},
			};
		},
	};
</script>
