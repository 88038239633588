<template>
	<!-- Static sidebar for desktop -->
	<div class="hidden md:flex md:flex-shrink-0">
		<div class="flex flex-col w-64">
			<!-- Sidebar component, swap this element with another sidebar if you like -->
			<div class="flex flex-col h-0 flex-1">
				<div
					class="flex items-center h-16 flex-shrink-0 px-4 bg-gray-700"
				>
					<Logo class="h-10"></Logo>
				</div>
				<div class="flex-1 flex flex-col overflow-y-auto">
					<nav-list></nav-list>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapMutations } from 'vuex';
	import NavList from './cohort/navList.vue';
	import Logo from '../assets/svg/logo.svg';
	export default {
		name: 'Sidebar',
		components: {
			NavList,
			Logo,
		},
		methods: {
			...mapMutations(['setMobileNavWrapper', 'setSlideoverWrapper']),
		},
	};
</script>
>
