<template>
	<div
		class="border-l border-gray-400 border-dashed ml-5"
	>
		<div
			class="child-link relative flex text-sm justify-between items-center w-full group"
		>
			<div class="absolute h-px w-2 bg-gray-400"></div>
			<div class="py-2 flex w-full ml-3">
				<button
					v-if="cohort.cohorts.length"
					class="text-xs uppercase"
					@click="show = !show"
				>
					<ChevronDown class="w-4" v-if="show" />
					<!-- <ChevronUp class="w-4" v-if="!show" /> -->
					<ChevronRight class="w-4" v-if="!show" />
				</button>
				<div
					v-if="!cohort.cohorts.length"
					class="text-xs uppercase flex items-center"
				>
					<CohortIcon class="w-4" />
				</div>
				<router-link
					@click.native="show = true"
					class="flex-1 ml-3"
					:to="{
						name: 'cohort.show',
						params: {
							id: $store.state.cohorts.root,
							current: cohort.id,
						},
					}"
				>
					<div
						:class="{
							' text-indigo-600 underline': selected,
						}"
					>
						{{ cohort.name }}
					</div>
					<div class="text-xs text-gray-500">
						<span class="mr-3">({{cohort.people_count}})</span>
						<span v-if="cohort.cohorts.length"
							>{{ cohort.cohorts.length }}
							{{
								$t(
									cohort.cohorts.length > 1
										? 'cohort.cohorts'
										: 'cohort.cohort'
								)
							}}</span
						>
					</div>
				</router-link>
			</div>
			<div class="space-x-3 flex items-center">
				<button
				v-if="cohort.people_count == 0"
					class="group-hover:opacity-100 opacity-0 transition-opacity ease-linear delay-150 duration-50 text-xs text-blue-100 bg-blue-600 p-1 mr-2 leading-none rounded"
					@click="
						setSlideoverWrapper({
							current: true,
							related_id: cohort.id,
						})
					"
				>
					Add sub cohort
				</button>
			</div>
			<div class="py-2">
				<input
					id="sms"
					name="sms"
					type="checkbox"
					:checked="isChecked"
					class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
					:value="cohort.id"
					@change="toggleSms"
				/>
				<CohortError class="mt-1" :error="cohort.error" />
			</div>
		</div>
		<div class="overflow-hidden">
			<transition
				enter-active-class="transition ease-in-out duration-300 transform"
				enter-class="-translate-y-full"
				enter-to-class="translate-y-0"
				leave-active-class="transition ease-in-out duration-300 transform"
				leave-class="translate-y-0"
				leave-to-class="-translate-y-full"
			>
				<div v-show="show" class="relative">
					<child
						:number="indentation"
						v-for="child in sorted"
						:key="child.id"
						:cohort="child"
						:is-parent-checked="isChecked"
					></child>
				</div>
			</transition>
		</div>
	</div>
</template>
<script>
	import ChevronDown from '../../assets/svg/chevron_down.svg';
	import ChevronRight from '../../assets/svg/chevron_right.svg';
	import CohortIcon from '../../assets/svg/cohort.svg';
	import { mapMutations, mapGetters } from 'vuex';
	import CohortError from './cohortError.vue';
	export default {
		name: 'cohortChild',
		components: {
			CohortIcon,
			ChevronRight,
			ChevronDown,
			child: () => import('./child.vue'),
			CohortError,
		},
		created() {
			if (this.number == undefined) {
				this.indentation = 0;
			} else {
				this.indentation = this.number + 1;
			}
			this.$root.$on('sms-toggle', ({ status, id }) => {
				if (id === this.cohort.cohort_id) {
					this.isChecked = status;
					this.show = status;
				}
			});
		},
		props: ['cohort', 'number', 'isParentChecked'],
		methods: {
			...mapMutations([
				'setSlideoverWrapper',
				'toggleSelected',
				'setNavState',
			]),
			toggleSms(event) {
				this.isChecked = event.target.checked;
				if (this.isChecked) {
					this.show = true;
				}
				this.$root.$emit('sms-toggle', {
					status: event.target.checked,
					id: event.target.value,
				});
			},
		},
		watch: {
			isParentChecked() {
				this.$root.$emit('sms-toggle', {
					status: this.isParentChecked,
					id: this.cohort.id,
				});
				this.show = this.isParentChecked;
				this.isChecked = this.isParentChecked;
			},
		},
		computed: {
			...mapGetters(['getNavState']),
			selected() {
				return this.cohort.id == this.$store.state.cohorts.related;
			},
			nav() {
				return this.getNavState[this.cohort.id]
					? this.getNavState[this.cohort.id]
					: false;
			},
			sorted() {
				const ch = this.cohort.cohorts;
				return ch.sort((a, b) => {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				});
			},
		},
		data() {
			return {
				show: false,
				indentation: 0,
				isChecked: false,
			};
		},
	};
</script>
<style>
	.child-link {
		min-height: 49px;
	}
</style>