<template>
	<div>
		<!-- This example requires Tailwind CSS v2.0+ -->
		<transition
			enter-active-class="transform ease-out duration-300 transition"
			enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
			enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
			leave-active-class="transition ease-in duration-100"
			leave-class="opacity-100"
			leave-to-class="opacity-0"
		>
			<div
				v-if="show_toast"
				class="fixed bottom-0 right-0 left-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end"
			>
				<div
					class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
				>
					<div class="p-4">
						<div class="flex items-start">
							<div class="flex-shrink-0">
								<checkSircle
									class="h-6 w-6 text-green-400"
									v-if="current_state == 'completed'"
								/>
								<loader
									class="w-6 animate-spin"
									v-if="current_state == 'default'"
								/>
							</div>
							<div class="ml-3 w-0 flex-1 pt-0.5">
								<p
									class="text-sm font-medium text-gray-900"
									v-if="current_state == 'completed'"
								>
									Successfully saved!
								</p>
								<p
									class="text-sm font-medium text-gray-900"
									v-if="current_state == 'default'"
								>
									Generating pdf
								</p>
								<div
									class="mt-2 relative h-2 rounded-full bg-gray-200"
								>
									<div
										class="bg-green-500 h-2 rounded-full absolute left transition"
										:style="`width:${progress}%`"
									></div>
								</div>
							</div>
							<div class="ml-4 flex-shrink-0 flex">
								<button
									@click="show_toast = false"
									class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
								>
									<span class="sr-only">Close</span>
									<!-- Heroicon name: x -->
									<svg
										class="h-5 w-5"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true"
									>
										<path
											fill-rule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clip-rule="evenodd"
										/>
									</svg>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
		<vue-html2pdf
			:show-layout="false"
			:float-layout="true"
			:enable-download="true"
			:preview-modal="false"
			:mode="'client'"
			:paginate-elements-by-height="1400"
			:filename="'QR-code ' + Cohort.name"
			:pdf-quality="2"
			:manual-pagination="false"
			pdf-format="a4"
			pdf-orientation="portrait"
			pdf-content-width="800px"
			@progress="onProgress($event)"
			@hasStartedGeneration="hasStartedGeneration()"
			@hasGenerated="hasGenerated($event)"
			@hasDownloaded="hasDownloaded()"
			ref="html2Pdf"
		>
			<section
				slot="pdf-content"
				class="flex flex-col justify-between text-center"
				style="height: 1121px; background: #e1e8f1"
			>
				<div class="flex-1 bg-white py-24">
				<p class="text-gray-600 font-bold text-xs mb-6">KOHORT QR-KODE FOR</p>
					<h1 class="text-5xl text-gray-800 font-bold uppercase">
						{{ Cohort.name }}
					</h1>
					<h2 class="text-xl mt-6">{{path.reverse().join(', ')}}</h2>
					<div>
						<vue-qr
							class="mx-auto mt-10"
							:size="300"
							:text="Cohort.public_id"
						/>
					</div>
				</div>
				<div class="flex  flex-col pt-10 justify-between px-10 text-xs">
					<div
						class="grid grid-cols-3 gap-5 uppercase text-gray-600 font-bold"
					>
						<div class="flex flex-col bg-white p-4 relative">
							<div class="absolute -top-3 -left-3 bg-blue-200 rounded-full flex items-center justify-center text-blue-800 h-8 w-8">1</div>
							<div class=" uppercase">Last ned app</div>
							<div class="h-24 flex items-center justify-center">
								<downloadApp class="w-24 " />
							</div>
							<p class="text-left normal-case text-xs font-normal">Åpne <span class="text-blue-600">app.salivacode.com</span><br> Last ned appen, og registrer deg ved å velge: <strong>BRUK SALIVACODE MED QR-KODE</strong></p>
						</div>
						<div class="flex flex-col bg-white p-4 relative">
							<div class="absolute -top-3 -left-3 bg-blue-200 rounded-full flex items-center justify-center text-blue-800 h-8 w-8">2</div>
							<div class="uppercase">Scann kohort QR-kode</div>
							<div class="h-24 flex items-center justify-center">
								<popleQr class="w-24" />
							</div>
							<p class="text-left normal-case text-xs font-normal">Scann koden øverst på denne siden med SalivaCODE-appen. Du blir da medlem i kohorten.</p>
						</div>
						<div class="flex flex-col bg-white p-4 relative">
							<div class="absolute -top-3 -left-3 bg-blue-200 rounded-full flex items-center justify-center text-blue-800 h-8 w-8">3</div>
							<div class="uppercase">Følg instruksjoner</div>
							<div class="h-24 flex items-center justify-center">
								<svg width="94" height="22" viewBox="0 0 94 22" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="94" height="21.7521" rx="6" fill="#3182CE"/>
									<path d="M30.4263 9.17031H32.0038V13.5948H32.7654V9.17031H34.3454V8.50987H30.4263V9.17031Z" fill="#F7FAFC"/>
									<path d="M35.034 13.5948L35.4989 12.2491H37.477L37.9394 13.5948H38.7504L36.9281 8.50987H36.0454L34.223 13.5948H35.034ZM35.7214 11.6035L36.4682 9.43349H36.5077L37.2545 11.6035H35.7214Z" fill="#F7FAFC"/>
									<path d="M41.3244 13.5948H42.0885V11.8071H43.127C44.3015 11.8071 44.8826 11.0945 44.8826 10.156C44.8826 9.21997 44.3065 8.50987 43.1295 8.50987H41.3244V13.5948ZM42.0885 11.1566V9.16783H43.0479C43.8045 9.16783 44.1136 9.57998 44.1136 10.156C44.1136 10.732 43.8045 11.1566 43.0578 11.1566H42.0885Z" fill="#F7FAFC"/>
									<path d="M45.7783 13.5948H46.5423V11.6706H47.5809C47.5982 11.6706 47.613 11.6706 47.6303 11.6706L48.6589 13.5948H49.5293L48.4067 11.5365C49.0273 11.3056 49.3364 10.7867 49.3364 10.1039C49.3364 9.16783 48.7578 8.50987 47.5833 8.50987H45.7783V13.5948ZM46.5423 11.0101V9.16783H47.5017C48.2559 9.16783 48.5674 9.52784 48.5674 10.1039C48.5674 10.6774 48.2559 11.0101 47.5116 11.0101H46.5423Z" fill="#F7FAFC"/>
									<path d="M50.8027 13.8157L51.1464 13.3092C51.4975 13.5401 51.9203 13.6643 52.3852 13.6643C53.6957 13.6643 54.665 12.6786 54.665 11.0523C54.665 10.2255 54.4152 9.56509 53.9974 9.11569L54.3608 8.58187L53.9479 8.29883L53.6141 8.79043C53.2655 8.56201 52.8451 8.44035 52.3852 8.44035C51.0698 8.44035 50.1029 9.42605 50.1029 11.0523C50.1029 11.8741 50.3502 12.5321 50.7656 12.984L50.3922 13.5327L50.8027 13.8157ZM53.9083 11.0523C53.9083 12.2913 53.2556 12.9641 52.3852 12.9641C52.0736 12.9641 51.7893 12.8772 51.5544 12.7109L53.5646 9.75378C53.7822 10.0691 53.9083 10.5061 53.9083 11.0523ZM51.2008 12.3434C50.9857 12.0281 50.862 11.5936 50.862 11.0523C50.862 9.81337 51.5124 9.14052 52.3852 9.14052C52.6918 9.14052 52.9737 9.22494 53.2086 9.3888L51.2008 12.3434Z" fill="#F7FAFC"/>
									<path d="M55.8086 8.50987H54.9827L56.805 13.5948H57.6878L59.5101 8.50987H58.6843L57.2748 12.6513H57.218L55.8086 8.50987Z" fill="#F7FAFC"/>
									<path d="M60.2414 13.5948H63.4459V12.9343H61.0054V11.3776H63.2506V10.7196H61.0054V9.17031H63.4163V8.50987H60.2414V13.5948Z" fill="#F7FAFC"/>
								</svg>

							</div>
							<p class="text-left normal-case text-xs font-normal">Appen gir deg gode instruksjoner ved innsamling av spyttprøve. Ha på lyd, og følg instruksjonene i appen nøye når du tar prøven.</p>
						</div>
					</div>
					<div class="pb-10 mt-10 flex items-start justify-start ">
						<salivaCode />
						<div class="ml-10">
							<ul class="text-left">
								<li class="list-disc" v-for="(item,key) in path.reverse()" :key="key">{{item}}</li>
								<li class="list-disc">{{Cohort.name}}</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
		</vue-html2pdf>
	</div>
</template>
<script>
	import VueHtml2pdf from 'vue-html2pdf';
	import salivaCode from '../../assets/svg/saliva_code.svg';
	import checkSircle from '../../assets/svg/check_circle.svg';
	import loader from '../../assets/svg/loader.svg';
	// import VueQrcode from 'vue-qr';
	import VueQr from 'vue-qr';
	import popleQr from '../../assets/svg/people_qr.svg';
	import downloadApp from '../../assets/svg/download_app.svg';
	import { mapGetters } from 'vuex';

	export default {
		mounted() {
			this.$root.$on('generate-report', this.generateReport);
		},
		data() {
			return {
				show_toast: false,
				current_state: 'default',
				progress: 0,
			};
		},
		methods: {
			getPath(obj, value) {
				var path = [this.Root.name];
				var found = false;

				function search(haystack) {
					for (var key in haystack) {
						
						if(haystack[key].id === value) {
							found = true;
							break;
						}
						path.push(haystack[key].name);
						search(haystack[key].cohorts);
						if(found) break;
						path.pop();
					}
				}
				search(obj.cohorts);
				return path;
			},
			/*
			Generate Report using refs and calling the
			refs function generatePdf()
			*/
			onProgress(e) {
				console.log(e);
				this.progress = e;
			},
			hasDownloaded() {
				this.current_state = 'completed';
				this.progress = 0;
				setTimeout(() => {
					this.show_toast = false;
				}, 5000);
			},
			hasStartedGeneration() {},
			generateReport() {
				this.show_toast = true;
				this.$refs.html2Pdf.generatePdf();
			},
		},
		computed: {
			...mapGetters(['Cohort', 'Root', 'Parent']),
			path() {
				return this.getPath(this.Root,this.Cohort.id);
			}
		},
		components: {
			VueHtml2pdf,
			salivaCode,
			checkSircle,
			loader,
			VueQr,
			popleQr,
			downloadApp,
		},
	};
</script>
