<template>
	<transition
		enter-active-class="transform ease-out duration-300 transition"
		enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
		enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
		leave-active-class="transition ease-in duration-100"
		leave-class="opacity-100"
		leave-to-class="opacity-0"
	>
		<div
			v-if="!isEmpty"
			class="fixed bottom-0 right-0 left-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end"
		>
			<div
				class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
			>
				<div class="p-4">
					<div class="flex items-start">
						<div class="flex-shrink-0">
							<svg
								width="23"
								height="23"
								viewBox="0 0 23 23"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle
									cx="11.5"
									cy="11.5"
									r="11"
									stroke="#E53E3E"
								/>
								<path
									d="M10.4058 13.844V16H12.6038V13.844H10.4058ZM12.5338 8.692V6.004H10.4618V8.692L10.9938 13.004H11.9878L12.5338 8.692Z"
									fill="#E53E3E"
								/>
							</svg>
						</div>
						<div class="ml-3 w-0 flex-1 pt-0.5 text-red-600">
							<p class="text-sm font-medium">
								{{ getErrors[Object.keys(getErrors)[0]] }}
							</p>
						</div>
						<div class="ml-4 flex-shrink-0 flex">
							<button
								@click="setError({})"
								class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							>
								<span class="sr-only">Close</span>
								<!-- Heroicon name: x -->
								<svg
									class="h-5 w-5"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clip-rule="evenodd"
									/>
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
	import { mapGetters } from 'vuex';
	export default {
		computed: {
			...mapGetters(['getErrors']),
			isEmpty() {
				return Object.keys(this.getErrors).length === 0;
			},
		},
		watch: {
			getErrors: {
				deep: true,
				handler(e) {
					this.errors = e;
					if (!this.isEmpty) {
						setTimeout(() => {
							console.log('closing');
							this.$store.commit('setErrors', {});
						}, 5000);
					}
				},
			},
		},
		methods: {
			setError(e) {
				this.$store.commit('setErrors', e);
			},
		},
		data() {
			return {
				show_toast: false,
				errors: {},
			};
		},
	};
</script>
