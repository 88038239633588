export const flatten = (data, key = 'key') => {
	const result = [];
	const recurse = (cur, prop) => {
		cur.forEach((item) => {
			const children = item[prop];
			result.push(item);
			recurse(children, prop);
		});
	};
	recurse(data, key);
	return result;
};
