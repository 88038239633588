import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { flatten } from './helpers/utils';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);
import Router from '../router';

const state = {
	user: null,
	business: null,
	cohorts: {
		all: [],
		current: null,
		related: null,
		root: null,
	},
	access_token: null,
	site: {
		mobile_nav: {
			wrapper: false,
			inner: false,
		},
		slideover: {
			wrapper: false,
			inner: false,
		},
		sms: {
			wrapper: false,
			inner: false,
		},
		navState: {},
	},
	errors: {},
};

const actions = {
	async createCohort({ commit,state }, {name, description, reference_1, cohort_id}) {
		let response = await axios.post('api/v1/cohort', {
			name: name,
			description: description,
			reference_1: reference_1,
			cohort_id: cohort_id,
		});
		await commit('setCohorts', response.data.data);
		commit('setSlideoverInner', false);
		Router.push({name:'cohort.show',params:{
			id:state.root ? state.root : response.data.data.id,
			current:response.data.data.id
		}});
	},
	async updateCohort({ commit,state }, {id,name, description, reference_1, cohort_id}) {
		let response = await axios.put(`api/v1/cohort/${id}`, {
			name: name,
			description: description,
			reference_1: reference_1,
			cohort_id: cohort_id,
		});
		await commit('setCohorts', response.data.data);
		commit('setSlideoverInner', false);
		Router.push({name:'cohort.show',params:{
			id:state.root ? state.root : response.data.data.id,
			current:response.data.data.id
		}});
	},
	async deleteCohort({ commit, state }, data) {
		if (!confirm('Er du sikker på at du ønsker å slette denne kohorten?')) {
			return false;
		}
		try {
			let response = await axios.delete(`api/v1/cohort/${data.id}`);
			await commit(
				'setCurrent',
				data.cohort_id ? data.cohort_id : state.cohorts.all[0].id
			);
			await commit('setCohorts', response.data.data);
		} catch (e) {
			commit('setErrors', e.response.data.errors);
		}
	},
	/* eslint-disable-next-line */
	async register({ commit }, data) {
		
		let response = await axios.post('api/v1/business', data);
		// await commit('setUser', response.data.data.user);
		// await commit('setToken', response.data.access_token);
		// await commit('setBusiness', response.data.data.business);
		return response;
	},
	/* eslint-disable-next-line */
	async resetPassword({ commit }, data) {
		await axios.post('api/v1/auth/cohort/reset-password', data);
	},
	/* eslint-disable-next-line */
	async createPassword({ commit }, data) {
		let response = await axios.post(
			'api/v1/auth/cohort/create-password',
			data
		);
		await commit('setUser', response.data.data.user);
		await commit('setToken', response.data.access_token);
		await commit('setBusiness', response.data.data.business);
		Router.push('/');
	},
	async logout({ commit, getters }, message = null) {
		if (getters.isAuthenticated) {
			const token = getters.User.access_token;
			await commit('clearUser');
			await axios.post('api/v1/auth/cohort/logout', {
				headers: {
					authorization: `Bearer ${token}`,
					accept: 'application/json',
				},
			});
			console.log(message);
			
		}
		await Router.push('/login');
	},
	async login({ commit }, User) {
		let response = await axios.post('api/v1/auth/cohort/login', User);
		try {
			await commit('setUser', response.data.data.user);
			await commit('setCohorts', response.data.data.cohorts);
			await commit('setToken', response.data.access_token);
			await commit('setBusiness', response.data.data.business);
		} catch (e) {
			console.log(e)
		}
		return response;
	},
	async updateUser({ commit }) {
		let response = await axios.get('api/v1/business/me');
		await commit('setUser', response.data.data.user);
		await commit('setCohorts', response.data.data.cohorts);
		await commit('setBusiness', response.data.data.business);
	},
};

const mutations = {
	setUser(state, user) {
		state.user = user;
	},

	setMobileNavWrapper(state, current) {
		state.site.mobile_nav.wrapper = current;
	},
	setMobileNavInner(state, current) {
		state.site.mobile_nav.inner = current;
	},
	setSmsWrapper(state, current) {
		state.site.sms.wrapper = current;
	},
	setSmsInner(state, current) {
		state.site.sms.inner = current;
	},
	setSlideoverWrapper(state, { current, related_id }) {
		state.site.slideover.wrapper = current;
		state.cohorts.related = related_id;
	},
	setSlideoverInner(state, current) {
		state.site.slideover.inner = current;
	},
	setToken(state, token) {
		state.access_token = token;
	},
	setCohorts(state, cohorts) {
		state.cohorts.all = cohorts;
	},
	setErrors(state, errors) {
		state.errors = errors;
	},
	setBusiness(state, business) {
		state.business = business;
	},
	setCurrent(state, id) {
		state.cohorts.current = id;
	},
	setParent(state, id) {
		state.cohorts.root = id;
	},
	setNavState(state, { cohort_id, isOpen }) {
		state.site.navState[cohort_id] = isOpen;
	},
	clearUser(state) {
		state.user = null;
		state.business = null;
		state.cohorts = {
			all: [],
			current: null,
			related: null,
			root: null,
		};
		state.site.navState = {};
		state.access_token = null;
	},
};

const getters = {
	getErrors: (state) => state.errors,
	isAuthenticated: (state) => !!state.user,
	User: (state) => state.user,
	Business: (state) => state.business,
	getNavState: (state) => state.site.navState,
	Cohorts: (state) =>
		state.cohorts.all.sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		}),
	Cohort: (state) => {
		/* eslint-disable-next-line */
		return flatten(state.cohorts.all, 'cohorts').find((cohort) => {
			return cohort.id == state.cohorts.current;
		});
	},
	Root: (state) => {
		return state.cohorts.all.find((cohort) => {
			return cohort.id == state.cohorts.root;
		});
	},
	Parent: (state, getters) => {
		return flatten(state.cohorts.all, 'cohorts').find((cohort) => {
			return cohort.id == getters.Cohort.cohort_id;
		});
	},
	mobile_nav: (state) => state.site.mobile_nav,
	sms: (state) => state.site.sms,
	slideover: (state) => state.site.slideover,
};

export default new Vuex.Store({
	plugins: [createPersistedState()],
	state,
	actions,
	mutations,
	getters,
});
