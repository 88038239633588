import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import './assets/tailwind.css';
import Store from './store';
import Router from './router';
import i18n from './i18n';
axios.defaults.withCredentials = true;
axios.defaults.baseURL =
	process.env.NODE_ENV == 'development'
		? 'https://staging.saliva.iotek.live'
		: 'https://api.salivacode.eu/';

import LayoutNotAuth from './layouts/layoutsNotAuth.vue';
import LayoutIsAuth from './layouts/layoutIsAuth.vue';

Vue.component('LayoutNotAuth', LayoutNotAuth);
Vue.component('LayoutIsAuth', LayoutIsAuth);
// Adds correct headers to request
axios.interceptors.request.use((req) => {
	req.headers.authorization = `Bearer ${Store.state.access_token}`;
	req.headers.accept = 'application/json';
	return req;
});
// Handles expired tokens correctly
axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response.status === 401 || error.response.status === 403) {
			//place your reentry code
		}
		return new Promise((resolve, reject) => {
			reject(error);
		});
	}
);
// axios.interceptors.response.use(undefined, function(error) {
// 	if (error) {
// 		const originalRequest = error.config;
// 		if (error.response.status === 401 && !originalRequest._retry) {
// 			originalRequest._retry = true;
// 			Store.dispatch('logout');
// 			return Router.push('/login');
// 		}
// 		return error;
// 	}
// });

Vue.config.productionTip = false;

new Vue({
	i18n,
	render: (h) => h(App),
	store: Store,
	router: Router,
	mounted() {
		Store.dispatch('updateUser');
	},
}).$mount('#app');
