import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

import HelloWorld from '../components/HelloWorld.vue';
import Login from '../pages/auth/login.vue';
import Register from '../pages/auth/register.vue';
import ForgotPassword from '../pages/auth/forgotPassword.vue';
import Pdf from '../pages/pdf/generator.vue';
import createPassword from '../pages/auth/createPassword.vue';
import Cohort from '../pages/cohort/Single.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Dashboard',
		component: HelloWorld,
		meta: { requiresAuth: true },
	},
	{
		path: '/cohort/:id/child/:current',
		name: 'cohort.show',
		component: Cohort,
		meta: { requiresAuth: true },
	},
	{
		path: '/pdf/:id',
		name: 'pdf.create',
		component: Pdf,
		meta: { requiresAuth: true },
	},
	{
		path: '/login',
		name: 'auth.login',
		component: Login,
		meta: { guest: true },
	},
	{
		path: '/register',
		name: 'auth.register',
		component: Register,
		meta: { guest: true },
	},
	{
		path: '/password-reset',
		name: 'auth.restorePassword',
		component: ForgotPassword,
		meta: { guest: true },
	},
	{
		path: '/create-password',
		name: 'auth.createPassword',
		component: createPassword,
		meta: { guest: true },
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});
router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (store.getters.isAuthenticated) {
			next();
			return;
		}
		next('/login');
	} else {
		next();
	}
});
router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.guest)) {
		if (store.getters.isAuthenticated) {
			next('/');
			return;
		}
		next();
	} else {
		next();
	}
});
export default router;
